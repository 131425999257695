import React, { useEffect, useRef, memo, useState } from "react";

function TradingViewWidget({ symbol = "", realTimeData, socketError }) {
  const [loading, setLoading] = useState(true);
  const [currentData, setCurrentData] = useState(realTimeData); // Store current valid data
  const containerRef = useRef(null);

  useEffect(() => {
    if (loading) {
      setTimeout(() => setLoading(false), 0); // Simulate loading completion
    } else {
      if (socketError || !realTimeData) {
        console.error("Socket error or no real-time data. Using fallback data.");
        // Fall back to previous valid data if error or no data
        setCurrentData((prevData) => prevData || realTimeData);
      } else {
        setCurrentData(realTimeData); // Update with new real-time data
      }

      // Clear any previously appended script to avoid multiple instances
      if (containerRef.current) {
        containerRef.current.innerHTML = ""; // Clean the container 
      }

      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;

      script.innerHTML = `
        {
          "autosize": true,
          "symbol": "BINANCE:${symbol || "BTCUSDT"}",
          "timezone": "Etc/UTC",
          "theme": "light",
          "style": "1",
          "locale": "en",
          "withdateranges": true,
          "range": "ALL",
          "hide_side_toolbar": false,
          "allow_symbol_change": false,
          "details": true,
          "calendar": false,
          "studies": [
            "STD;Hull%1MA",
            "STD;MA%1Cross"
          ],
          "support_host": "https://www.tradingview.com"
        }`;

      containerRef.current.appendChild(script); // Append the new script
    }
  }, [loading, symbol, socketError]); // Re-run effect when symbol, real-time data, or socket error changes

  return (
    <div
      className="tradingview-widget-container"
      ref={containerRef}
      style={{ height: "100%", width: "100%" }}
    >
      <div
        className="tradingview-widget-container__widget"
        style={{ height: "calc(100% - 32px)", width: "100%" }}
      ></div>
    </div>
  );
}

export default memo(TradingViewWidget);
