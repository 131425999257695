import React, { useEffect, useRef, useState } from "react";
import { Button, Card, ConfigProvider, InputNumber, message, Select, Slider, Spin, Tabs, Row, Col, Table } from "antd";
import TradingViewWidget from "../components/TradingViewWidget";
import { API_CALL } from "../utils/Network";
import { useMainContext } from "../utils/MainContext";

const { Option } = Select;
const { TabPane } = Tabs;

export const SYMBOL_OPTIONS = [
  "BTCUSDT",
  "DOGEUSDT",
  "ETHUSDT",
  "BNBUSDT",
  "SOLUSDT",
  "WLDUSDT",
  "AAVEUSDT",
  "RNDRUSDT",
  "WIFUSDT",
  "DOTUSDT",
  "ATOMUSDT",
  "SUIUSDT",
  "LTCUSDT",
  "NEARUSDT",
  "PEPEUSDT"
];

function Dashboard() {
  const { getSocketData } = useMainContext();
  const [selectedSymbol, setSelectedSymbol] = useState(SYMBOL_OPTIONS[0]);
  const [reset, setReset] = useState(true);
  const [buyOrderType, setBuyOrderType] = useState("limit");
  const [sellOrderType, setSellOrderType] = useState("limit");
  const [buyPrice, setBuyPrice] = useState(null);
  const [sellPrice, setSellPrice] = useState(null);
  const [buyQuantity, setBuyQuantity] = useState(null);
  const [sellQuantity, setSellQuantity] = useState(null);
  const [buyLeverage, setBuyLeverage] = useState(1);
  const [sellLeverage, setSellLeverage] = useState(1);
  const [buyTotalCost, setBuyTotalCost] = useState(0);
  const [sellTotalCost, setSellTotalCost] = useState(0);
  const [latestPrice, setLatestPrice] = useState(0);
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [assetBalance, setAssetBalance] = useState(0);
  const [orders, setOrders] = useState([]);
  const socketData=getSocketData();
  // WebSocket setup
  // useEffect(() => {
  //   // socket api to fetched for multiple coins--------------
  //   // const streams = SYMBOL_OPTIONS.map(symbol => `${symbol.toLowerCase()}@trade`).join('/');
  //   // const binanceWebSocketUrl = `wss://stream.binance.com:9443/ws?streams=${streams}`;

  //   // socket api to fetched for single coin------------------
  //   // const streams = `${selectedSymbol.toLowerCase()}@trade`;
  //   debugger;
  // const streams = SYMBOL_OPTIONS.map(symbol => `${symbol.toLowerCase()}@trade`).join('/');
  // const binanceWebSocketUrl = `wss://stream.binance.com:9443/ws?streams=${streams}`;
  // const socket = new WebSocket(binanceWebSocketUrl);

  // socket.onmessage = (event) => {
  //   const msg = JSON.parse(event.data);
  //   if (msg && msg.s === selectedSymbol && !existingSymbols.current.has(msg.t)) {
  //     existingSymbols.current.add(msg.t); // Assuming msg.t is a unique identifier
  //     setSocketData((prevData) => [...prevData, msg]);
  //     addSocketData(msg); // Use context to add socket data
  //   }
  // };

  // // return () => {
  // //   socket.close(); // Cleanup
  // // };
  // }, []);

  useEffect(() => {
    if (selectedSymbol) {
      // fetchUsdtBalance();
      // fetchAssetBalance(selectedSymbol.replace("USDT", ""));
      // fetchOrders();
      setReset(true);
      console.log(socketData,'hghgh')
      if (socketData && socketData[selectedSymbol] && socketData[selectedSymbol].length > 0) {
        const latestData = socketData[selectedSymbol][socketData[selectedSymbol].length - 1];
        setLatestPrice(latestData.p); 
        setReset(false); 
      }
    
    }
  }, [socketData,selectedSymbol]);
  

  useEffect(() => {
    calculateBuyTotalCost();
    calculateSellTotalCost();
  }, [buyQuantity, buyPrice, buyLeverage, buyOrderType, sellQuantity, sellPrice, sellLeverage, sellOrderType, latestPrice]);

  

  const fetchUsdtBalance = async () => {
    try {
      const response = await API_CALL.get('/balance');
      if (response.status === "success") {
        setUsdtBalance(parseFloat(response.balance));
      } else {
        message.error(response.message || "Failed to fetch USDT balance");
      }
    } catch (error) {
      message.error("Failed to fetch USDT balance");
      console.error("USDT balance fetch error:", error);
    }
  };

  const fetchAssetBalance = async (asset) => {
    try {
      const response = await API_CALL.get(`/asset_balance?symbol=${asset}`);
      if (response.status === "success") {
        setAssetBalance(parseFloat(response.data.balance));
      } else {
        message.error(response.message || `Failed to fetch ${asset} balance`);
      }
    } catch (error) {
      message.error(`Failed to fetch ${asset} balance`);
      console.error(`${asset} balance fetch error:`, error);
    }
  };

  const calculateBuyTotalCost = () => {
    const effectivePrice = buyOrderType === "market" ? latestPrice : buyPrice || latestPrice;
    setBuyTotalCost((buyQuantity * effectivePrice) / buyLeverage);
  };

  const calculateSellTotalCost = () => {
    const effectivePrice = sellOrderType === "market" ? latestPrice : sellPrice || latestPrice;
    setSellTotalCost(sellQuantity * effectivePrice);
  };

  const handleLeverageChange = (setLeverage, value) => {
    const validValue = Math.max(1, Math.min(100, value));
    setLeverage(validValue);
  };

  const handleOrder = async (side, orderType, price, quantity, leverage) => {
    const orderData = {
      symbol: selectedSymbol,
      order_type: orderType.toUpperCase(),
      side: side.toUpperCase(),
      quantity_usdt: quantity,
      price: orderType === "limit" ? price : undefined,
      leverage: leverage,
    };

    try {
      const response = await API_CALL.post("/place_order", orderData);
      if (response.status === "success") {
        message.success("Order placed successfully");
        fetchUsdtBalance();
        fetchAssetBalance(selectedSymbol.replace("USDT", ""));
        fetchOrders();
      } else {
        message.error(response.message || "Failed to place order");
      }
    } catch (error) {
      message.error("Failed to place order");
      console.error("Order placement error:", error);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await API_CALL.get('/orders');
      if (response.status === "success") {
        setOrders(response.data);
      } else {
        message.error(response.message || "Failed to fetch orders");
      }
    } catch (error) {
      message.error("Failed to fetch orders");
      console.error("Orders fetch error:", error);
    }
  };

  const columns = [
    { title: "Order ID", dataIndex: "order_id", key: "order_id" },
    { title: "Symbol", dataIndex: "symbol", key: "symbol" },
    { title: "Order Type", dataIndex: "order_type", key: "order_type" },
    { title: "Side", dataIndex: "side", key: "side" },
    { title: "Quantity (USDT)", dataIndex: "quantity_usdt", key: "quantity_usdt" },
    { title: "Price", dataIndex: "price", key: "price" },
    { title: "Leverage", dataIndex: "leverage", key: "leverage" },
    { title: "Status", dataIndex: "status", key: "status" },
  ];

  return (
    <Card bordered className="dashboard-wrapper" size={window.innerWidth < 767 ? "small" : "default"}>
    <div className="symbol-select-container">
      <span>Current Symbol :</span>
      <Select value={selectedSymbol} onChange={(symbol = "") => setSelectedSymbol(symbol)} style={{ minWidth: 120 }}>
        {SYMBOL_OPTIONS.map((symbol = "") => (
          <Select.Option key={symbol} value={symbol}>
            {symbol}
          </Select.Option>
        ))}
      </Select>
    </div>
    <div className="chart-container">
      <div className="spinner-container">
        <Spin size="large" />
      </div>
      {!reset &&<TradingViewWidget symbol={selectedSymbol} realTimeData={socketData} socketError={false} />
    }
    </div>
    <Row gutter={16}>
      <Col span={12}>
        <Card>
          <Tabs activeKey={buyOrderType} onChange={setBuyOrderType}>
            <TabPane tab="Limit" key="limit">
              <div className="custom-order-form">
                <div className="form-container">
                  <div className="input-container">
                    <label>Available Balance: <strong>{usdtBalance} USDT</strong></label>
                    <InputNumber
                      placeholder="Buy On Price"
                      addonAfter="USDT"
                      value={buyPrice}
                      onChange={setBuyPrice}
                      style={{ width: '100%' }}
                    />
                  </div>
                  <div className="input-container" style={{ marginTop: 16 }}>
                    <label>Quantity</label>
                    <InputNumber
                      placeholder="Quantity"
                      addonAfter={selectedSymbol.replace("USDT", "")}
                      value={buyQuantity}
                      onChange={(value) => setBuyQuantity(Math.min(value, usdtBalance))}
                      style={{ width: '100%' }}
                      max={usdtBalance}
                    />
                  </div>
                  <div className="price-details-container" style={{ marginTop: 16 }}>
                    <strong>Total</strong> <span style={{ paddingBottom: 2 }}>|</span>
                    <strong style={{ marginLeft: "auto" }}>{buyTotalCost.toFixed(6)} USDT</strong>
                  </div>
                  <div className="input-container" style={{ marginTop: 16 }}>
                    <label>Leverage</label>
                    <InputNumber
                      min={1}
                      max={5}
                      value={buyLeverage}
                      onChange={(value) => handleLeverageChange(setBuyLeverage, value)}
                      style={{ width: '100%' }}
                    />
                    <Slider
                      min={1}
                      max={5}
                      marks={{ 1: "1x", 3: "3x", 5: "5x"}}
                      value={buyLeverage}
                      onChange={(value) => handleLeverageChange(setBuyLeverage, value)}
                    />
                    <div style={{ textAlign: "center", marginTop: 8 }}>
                      <strong>{buyLeverage}x</strong>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Market" key="market">
              <div className="custom-order-form">
                <div className="form-container">
                  <div className="input-container">
                    <label>Available Balance: <strong>{usdtBalance} USDT</strong></label>
                    <InputNumber
                      placeholder="Quantity"
                      addonAfter={selectedSymbol.replace("USDT", "")}
                      value={buyQuantity}
                      onChange={(value) => setBuyQuantity(Math.min(value, usdtBalance))}
                      style={{ width: '100%' }}
                      max={usdtBalance}
                    />
                  </div>
                  <div className="price-details-container" style={{ marginTop: 16 }}>
                    <strong>Total</strong> <span style={{ paddingBottom: 2 }}>|</span>
                    <strong style={{ marginLeft: "auto" }}>{buyTotalCost.toFixed(6)} USDT</strong>
                  </div>
                  <div className="input-container" style={{ marginTop: 16 }}>
                    <label>Leverage</label>
                    <InputNumber
                      min={1}
                      max={5}
                      value={buyLeverage}
                      onChange={(value) => handleLeverageChange(setBuyLeverage, value)}
                      style={{ width: '100%' }}
                    />
                    <Slider
                      min={1}
                      max={5}
                      marks={{ 1: "1x", 3: "3x", 5: "5x" }}
                      value={buyLeverage}
                      onChange={(value) => handleLeverageChange(setBuyLeverage, value)}
                    />
                    <div style={{ textAlign: "center", marginTop: 8 }}>
                      <strong>{buyLeverage}x</strong>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </Tabs>
          <div className="btn-container">
            <ConfigProvider theme={{ token: { colorPrimary: "#00b96b" } }}>
              <Button size="large" type="primary" onClick={() => handleOrder("buy", buyOrderType, buyPrice, buyQuantity, buyLeverage)}>
                Buy {selectedSymbol.replace("USDT", "")}
              </Button>
            </ConfigProvider>
          </div>
        </Card>
      </Col>
      <Col span={12}>
        <Card>
          <Tabs activeKey={sellOrderType} onChange={setSellOrderType}>
            <TabPane tab="Limit" key="limit">
              <div className="custom-order-form">
                <div className="form-container">
                  <div className="input-container">
                    <label>Available {selectedSymbol.replace("USDT", "")} Balance: <strong>{assetBalance} {selectedSymbol.replace("USDT", "")}</strong></label>
                    <InputNumber
                      placeholder="Sell On Price"
                      addonAfter="USDT"
                      value={sellPrice}
                      onChange={setSellPrice}
                      style={{ width: '100%' }}
                    />
                  </div>
                  <div className="input-container" style={{ marginTop: 16 }}>
                    <label>Quantity</label>
                    <InputNumber
                      placeholder="Quantity"
                      addonAfter={selectedSymbol.replace("USDT", "")}
                      value={sellQuantity}
                      onChange={(value) => setSellQuantity(Math.min(value, assetBalance))}
                      style={{ width: '100%' }}
                      max={assetBalance}
                    />
                  </div>
                  <div className="price-details-container" style={{ marginTop: 16 }}>
                    <strong>Total</strong> <span style={{ paddingBottom: 2 }}>|</span>
                    <strong style={{ marginLeft: "auto" }}>{sellTotalCost.toFixed(6)} USDT</strong>
                  </div>
                  <div className="input-container" style={{ marginTop: 16 }}>
                    <label>Leverage</label>
                    <InputNumber
                      min={1}
                      max={5}
                      value={sellLeverage}
                      onChange={(value) => handleLeverageChange(setSellLeverage, value)}
                      style={{ width: '100%' }}
                    />
                    <Slider
                      min={1}
                      max={5}
                      marks={{ 1: "1x", 3: "3x", 5: "5"}}
                      value={sellLeverage}
                      onChange={(value) => handleLeverageChange(setSellLeverage, value)}
                    />
                    <div style={{ textAlign: "center", marginTop: 8 }}>
                      <strong>{sellLeverage}x</strong>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Market" key="market">
              <div className="custom-order-form">
                <div className="form-container">
                  <div className="input-container">
                    <label>Available {selectedSymbol.replace("USDT", "")} Balance: <strong>{assetBalance} {selectedSymbol.replace("USDT", "")}</strong></label>
                    <InputNumber
                      placeholder="Quantity"
                      addonAfter={selectedSymbol.replace("USDT", "")}
                      value={sellQuantity}
                      onChange={(value) => setSellQuantity(Math.min(value, assetBalance))}
                      style={{ width: '100%' }}
                      max={assetBalance}
                    />
                  </div>
                  <div className="price-details-container" style={{ marginTop: 16 }}>
                    <strong>Total</strong> <span style={{ paddingBottom: 2 }}>|</span>
                    <strong style={{ marginLeft: "auto" }}>{sellTotalCost.toFixed(6)} USDT</strong>
                  </div>
                  <div className="input-container" style={{ marginTop: 16 }}>
                    <label>Leverage</label>
                    <InputNumber
                      min={1}
                      max={5}
                      value={sellLeverage}
                      onChange={(value) => handleLeverageChange(setSellLeverage, value)}
                      style={{ width: '100%' }}
                    />
                    <Slider
                      min={1}
                      max={5}
                      marks={{ 1: "1x", 3: "3x", 5: "5x" }}
                      value={sellLeverage}
                      onChange={(value) => handleLeverageChange(setSellLeverage, value)}
                    />
                    <div style={{ textAlign: "center", marginTop: 8 }}>
                      <strong>{sellLeverage}x</strong>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </Tabs>
          <div className="btn-container">
            <Button size="large" type="primary" danger onClick={() => handleOrder("sell", sellOrderType, sellPrice, sellQuantity, sellLeverage)}>
              Sell {selectedSymbol.replace("USDT", "")}
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
    <Card style={{ marginTop: 16 }}>
      <Table columns={columns} dataSource={orders} rowKey="order_id" />
    </Card>
    {/* <Card>
        <h2>Real-Time Data</h2>
        <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
          {socketData.map((data, index) => (
            <p key={index}>{JSON.stringify(data)}</p>
          ))}
        </div>
      </Card> */}
    </Card>
  );
}

export default Dashboard;
