import React from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";

import { useMainContext } from "../utils/MainContext";

function SideNav({ routes = [] }) {
  const { pathname = "" } = useLocation();
  const { clearContext } = useMainContext();

  return (
    <aside>
      <Menu
        mode="inline"
        selectedKeys={[pathname]}
        items={routes.map((route) => ({
          key: route.path,
          label: (
            <Link to={route.path}>
              <strong style={{ fontSize: "2vmin" }}>{route.label}</strong>
            </Link>
          ),
        }))}
      />
      <Menu
        mode="inline"
        style={{ marginTop: "auto" }}
        items={[
          {
            key: "/",
            label: (
              <Link to="/" onClick={() => clearContext()}>
                <strong style={{ fontSize: "2vmin" }}>Logout</strong>
              </Link>
            ),
          },
        ]}
      />
    </aside>
  );
}

export default SideNav;
