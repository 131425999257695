import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./Login";
import Dashboard, { SYMBOL_OPTIONS } from "./Dashboard";
import Orders from "./Orders";
import Settings from "./Settings";
import SideNav from "../components/SideNav";
import { useMainContext } from "../utils/MainContext";

const routes = [
  { label: "Dashboard", path: "/", component: Dashboard },
  { label: "Orders", path: "/orders", component: Orders },
  { label: "Settings", path: "/settings", component: Settings },
];

function Main() {
  const { getContext,addSocketData,getSocketData } = useMainContext();
const socketData=getSocketData();
  useEffect(() => {
    const symbolOptions = SYMBOL_OPTIONS;
    const webSocketConnections = [];
  
    // Delay function to introduce a delay between connection setups
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  
    const setupWebSocketConnections = async () => {
      for (let i = 0; i < symbolOptions.length; i++) {
        const symbol = symbolOptions[i];
        const streams = `${symbol.toLowerCase()}@trade`;
        
        try {
          const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${streams}`);
  
          ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            addSocketData(symbol, data); 
          };
  
          ws.onclose = () => {
            console.log(`${symbol} WebSocket closed`);
          };
  
          webSocketConnections.push(ws);
          await delay(5000); 
        } catch (error) {
          console.error(`Failed to connect to WebSocket for ${symbol}:`, error);
        }
      }
    };
  
    setupWebSocketConnections();
  
    return () => {
      // Clean up WebSocket connections on unmount
      // webSocketConnections.forEach((ws) => ws.close());
    };
  }, []);
  
  return (
    <BrowserRouter>
      <header>Crypto Wrapper</header>
      <main>
        {getContext("isLoggedIn") ? (
          <>
            <SideNav routes={routes} />
            <section>
              <Routes>
                {routes.map((route = {}) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}
              </Routes>
            </section>
          </>
        ) : (
          <Login />
        )}
      </main>
      <footer>Copyright Protected &#169;</footer>
    </BrowserRouter>
  );
}

export default Main;
