import React, { useState, useEffect } from "react";
import { Card, Input, Select, Button, Flex, Tag, message, Table, Popconfirm } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import { API_CALL } from "../utils/Network";

function Settings() {
  const [apiKey, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [exchange, setExchange] = useState("Binance");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const handleSave = async () => {
    setLoading(true);
    const requestData = { apiKey: apiKey, secretKey: secretKey, exchange: exchange };
    API_CALL.post("/keys", requestData)
      .then((response) => {
        if (response.status === 'success') {
          message.success(response.data.message || "API keys saved successfully");
          fetchApiKeys();
        } else {
          message.error("Failed to save API keys");
        }
        setLoading(false);
      })
      .catch(() => {
        message.error("Failed to save API keys");
        setLoading(false);
      });
  };

  const fetchApiKeys = async () => {
    setLoading(true);
    try {
      const response = await API_CALL.get("/keys");
      if (response.status === 'success') {
        setData(response.data);
      } else {
        message.error("Failed to fetch API keys");
      }
    } catch (error) {
      message.error("Failed to fetch API keys");
    }
    setLoading(false);
  };

  const handleDelete = async (keyId) => {
    setLoading(true);
    try {
      const response = await API_CALL.delete(`/keys/${keyId}`);
      if (response.status === 'success') {
        message.success(response.message || "API key deleted successfully");
        fetchApiKeys();
      } else {
        message.error("Failed to delete API key");
      }
    } catch (error) {
      message.error("Failed to delete API key");
    }
    setLoading(false);
  };

  const columns = [
    { title: "API Key", dataIndex: "apiKey", key: "apiKey" },
    { title: "Secret Key", dataIndex: "secretKey", key: "secretKey" },
    { title: "Exchange", dataIndex: "exchange", key: "exchange" },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Popconfirm
          title="Are you sure to delete this API key?"
          onConfirm={() => handleDelete(record._id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <Card
      bordered
      className="settings-wrapper"
      size={window.innerWidth < 767 ? "small" : "default"}
    >
      <div>
        <h3>API Key</h3>
        <Input
          size="large"
          value={apiKey}
          placeholder="Enter API Key"
          onChange={(e) => setApiKey(e.target.value)}
        />
      </div>
      <div>
        <h3>Secret Key</h3>
        <Input
          size="large"
          value={secretKey}
          placeholder="Enter Secret Key"
          onChange={(e) => setSecretKey(e.target.value)}
        />
      </div>
      <div>
        <h3>Exchange</h3>
        <Select
          style={{ width: "30%" }}
          size="large"
          placeholder={"Select Symbol"}
          defaultValue={exchange}
          onChange={(value) => setExchange(value)}
        >
          <Select.Option value="binance">Binance</Select.Option>
        </Select>
      </div>
      <Flex justify="space-between" style={{ marginTop: 20 }}>
        <Button type="primary" size="large" onClick={handleSave}>
          Save
        </Button>
      </Flex>
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        rowKey="_id"
        style={{ marginTop: 20 }}
      />
    </Card>
  );
}

export default Settings;
