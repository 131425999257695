import { message } from "antd";
import React, { createContext, useContext, useState } from "react";

const MainContext = createContext();

const MainContextProvider = ({ children = null }) => {
  const [state, setState] = React.useState(
    localStorage.getItem("local_state")
      ? JSON.parse(localStorage.getItem("local_state"))
      : {}
  );
    // New state for socket data
    const [socketData, setSocketData] = useState([]);


  const setContext = (updatedState = {}) => {
    const newState = { ...state, ...updatedState };
    localStorage.setItem("local_state", JSON.stringify(newState));
    setState(newState);
  };

  const getContext = (key = "") => {
    if (key) return state[key];
    else return state;
  };

  const clearContext = () => {
    localStorage.clear();
    setState({});
    message.success("Logged out successfully");
  };

  const addSocketData = (symbol, newData) => {
    setSocketData((prevData) => ({
      ...prevData,
      [symbol]: [...(prevData[symbol] || []), newData] // Append new data to the existing array
    }));
  };

  const getSocketData = () => socketData;

  return (
    <MainContext.Provider  value={{
      getContext,
      setContext,
      clearContext,
      addSocketData, // Expose function to add socket data
      getSocketData,  // Expose function to retrieve socket data
      
    }}>
      <div className="wrapper">{children}</div>
    </MainContext.Provider>
  );
};

const useMainContext = () => useContext(MainContext);

export { MainContext, MainContextProvider, useMainContext };
