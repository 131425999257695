import React from "react";
import { ConfigProvider } from "antd";

import { MainContextProvider } from "./utils/MainContext";
import Main from "./pages";

function App() {
  return (
    <ConfigProvider theme={{ token: { colorPrimary: "#0096FF" } }}>
      <MainContextProvider>
        <Main />
      </MainContextProvider>
    </ConfigProvider>
  );
}

export default App;
